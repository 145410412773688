import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useUser } from "next-firebase-auth";
import { useRouter } from "next/router";
import { Avatar } from "primereact/avatar";
import { useOrg } from "./OrgContext";
import Link from "next/link";

export default function OrgSwitcher({}) {
  const { activeOrg, updateActiveOrg, orgs } = useOrg();
  const AuthUser = useUser();
  const router = useRouter();

  const handleOrgChange = (org) => {
    // debugger;
    updateActiveOrg(org);
  };

  function stringAvatar(name) {

    if(name){

      return name.split(" ").length > 1
      ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
      : `${name.split(" ")[0][0]}${name.split(" ")[0][1]}`;
    }

    return name;
  }

  if (!orgs || orgs.length === 0) {
    return null; // Don't render the component until orgs is loaded
  }

  return (
    <div className="p-1">
      <div className="text-gray-500 text-xs font-medium">Organization</div>
      <Menu as="div" className="relative inline-block text-left mt-1 w-60">
        <div>
          <Menu.Button className="inline-flex w-full gap-x-1.5 rounded-md bg-white px-3 py-1 text-sm font-semibold text-gray-900 ring-0 ring-inset ring-gray-300 hover:bg-gray-50 -ml-3">
            <span className="inline-flex h-6 w-8 items-center justify-center rounded-md">
              <Avatar label={stringAvatar(activeOrg.name)} />
            </span>
            {activeOrg.name}
            <ChevronDownIcon
              className="-mr-1 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute z-10 mt-3 w-96 right-0 text-gray-500 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer">
            <div className="flex mb-3 items-center">
              <div className="px-4 pt-4 w-full text-xl font-medium text-black">
                Organizations
              </div>
            </div>
            <hr className="" />
            <div className="mb-4">
              {orgs.map((org, i) => (
                <Menu.Item
                  className={"hover:bg-gray-100 px-2"}
                  key={i}
                  onClick={(e) => {
                    handleOrgChange(org);
                  }}
                >
                  <div className={`flex pt-[15px] items-center`}>
                    <div>
                      <Avatar label={stringAvatar(org.name)} />
                    </div>
                    <div className="ml-3 text-xs font-normal w-full">
                      <span className="mb-1 text-sm font-medium text-gray-900">
                        {org.name}
                      </span>
                      <div className="mb-2 text-xs font-normal">
                        {org.orgId}
                      </div>
                    </div>
                    {activeOrg.orgId === org.orgId && (
                      <div className="text-[10px] mr-0 ml-auto text-gray-400 border border-gray-400 rounded-full h-max px-1">
                        Current
                      </div>
                    )}
                  </div>
                </Menu.Item>
              ))}
            </div>

            <div>
              <hr className="mb-2" />
              <div className="flex mt-3  px-4 pb-4 w-full justify-items-center">
                <div className="ml-3 justify-items-start w-full text-sm text-blue-600">
                  <Link
                    href={`/org/${activeOrg.orgId}/org-create`}
                    onClick={close}
                  >
                    + Add Org
                  </Link>
                </div>
                <button className="w-20 justify-items-end text-xs font-semibold border border-gray-400 rounded">
                  <Link href={`/orgs`} onClick={close}>
                    View all
                  </Link>
                </button>
              </div>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
