import firebaseDbGateway from '../gateways/firebaseDbGateway';
import {getDocument, updateDocument } from '../utils/firebaseUtils';
import { getUserMemberships } from "../utils/orgDataHelper";

async function getUser(userId) {
  const user =  await getDocument('users', userId);
  return user;
}

async function updateUser(userId, data) {
  await updateDocument('users', userId, data);
  
  return {
    success: true
  }
}

export async function deleteInviteData(id){
  return firebaseDbGateway.deleteDocumentFromCollection('invites', id);
}

async function orgAuth({ queryKey }) {
  const [_, uid, pid, pmid, taid] = queryKey;

  // const firstOrgId = await getUserMemberships(uid);
  const userOrgsMemberships = await getUserMemberships(uid);

  const firstOrgId = userOrgsMemberships[0].orgId;
  
  // debugger;
  const orgsSnap = await firebaseDbGateway.queryCollection('memberships', [
    ['uid', '==', uid],
  ])

  const orgs = orgsSnap.data;
  const authOrg = orgs.filter(data => data.orgId === firstOrgId);

  if (!pid && !pmid && !taid) return authOrg.length > 0;

  if (pmid) {
    const pageMonitorSnap = await firebaseDbGateway.queryCollection('pageMonitors', [
      ['orgId', '==', firstOrgId]
    ]);

    const pageMonitors = pageMonitorSnap.data;
    const authPageMontior = pageMonitors.filter(data => data.id === pmid);

    return authPageMontior.length > 0;
  }

  if (pid) {
    const websitesSnap = await firebaseDbGateway.queryCollection('websites', [
      ['orgId', '==', firstOrgId]
    ]);

    const websites = websitesSnap.data;
    const authWebsite = websites.filter(data => data.id === pid);

    return authWebsite.length > 0;
  }

  // tech-audit not yet implemented
  if (taid) {
    const techSnap = await firebaseDbGateway.queryCollection('techAudits', [
      ['orgId', '==', firstOrgId]
    ]);

    const techAudit = techSnap.data;
    const authTech = techAudit.filter(data => data.id === taid);

    return authTech.length > 0;
  }

}

export {
  getUser,
  updateUser,
  orgAuth
}