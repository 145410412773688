import configProd from './config-prod';
import configDev from './config-dev';
import configLocal from './config-local';

export const APP_VERSION = '2.1.0';
export const API_BASE_URL = '/api';

let CONFIG;
CONFIG = process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID === 'ranktivity-prod-45a98' ? configProd : configDev;

if (process.env.NODE_ENV === 'development') {
  CONFIG = configLocal;
}

// console.log(CONFIG);
export default CONFIG;
