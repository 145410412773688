import React from 'react'

const styles = {
  container: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)" 
  },
}

const FullPageLoader = () => (
  <div style={styles.container}>
    <img className='items-center w-[30vh] flex justify-center h-auto' src='/ranktivity_logo-2.gif' alt="#" />
  </div>
)

export default FullPageLoader
