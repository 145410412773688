import React, { createContext, useContext } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { getCurrentFeatureLimits } from '../utils/getCurrentFeatureLimits'; // Your API function to get limits

const LimitsContext = createContext();

export const useLimits = () => useContext(LimitsContext);

export const LimitsProvider = ({ orgId, children }) => {

  // debugger;
  const { data, error, isLoading } = useQuery(
    ['featureLimits', orgId], 
    () => getCurrentFeatureLimits(orgId),
    {
      enabled: !!orgId, // Only run the query if orgId is available
    }
  );

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <LimitsContext.Provider value={data?.limits || {}}>
      {children}
    </LimitsContext.Provider>
  );
};
