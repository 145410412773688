import firebaseDbGateway from "../gateways/firebaseDbGateway";
import httpGateway from "../gateways/httpGateway";
import moment from "moment";
import CONFIG from "../utils/config";

async function getPrimaryOrg(userId) {
  const orgResp = await firebaseDbGateway.queryCollection("orgs", [
    ["created_by_uid", "==", userId],
  ]);
  if (orgResp.data.length < 1) {
    throw new Error("Could not get organization data for userId");
  }
  return orgResp.data[0];
}
/**
 *
 * @param {*} userId
 * @param {*} token
 * @returns
 */
async function getSubscription(oid, userId, token) {
  
  console.log(`getSubscription for ${oid}`);
  // Get subscription doc from org this doc ID is the subscription ID in striope
  // Create a reference to the subscriptions collection
  const subscriptionsRef = await firebaseDbGateway.queryCollection(
    `orgs/${oid}/subscriptions`
  );

  console.log(subscriptionsRef.data[0].id);

  if (!subscriptionsRef.data.length > 0) {
    throw new Error("Subscription not found");
  }

  const subscription = subscriptionsRef.data[0];

  const orgResp = await firebaseDbGateway.getDocument(`orgs`, oid);
  const stripeCustomerId = orgResp.data.stripeId;
  console.log('orgResp')
  console.log(orgResp)

  console.log(`stripeCustomerId is ${stripeCustomerId}`);
  let customer;
  try {
     customer = (
      await httpGateway.get(
        `${process.env.APP_API_HOST}/stripe/customers/${stripeCustomerId}`,
        token
      )
    ).data;
  } catch (error) {
    console.log(error);
  }
  //  console.log('customer', customer)
  if (!customer.id) {
    throw new Error("Network Error: Could not retrieve customer data");
  }
  const default_payment_method = customer.custom__default_payment_method;

  const subscriptionData = {
    id: subscription.id,
    orgId: oid,

    planFeatures: orgResp.data.planFeatures,
    customerId: customer.id,
    default_payment_method: default_payment_method || null,
    ...subscription,
  };

  // console.log(subscriptionData);
  // console.log(`subscriptionData.items.data[0].plan.id is ${subscription.items[0].plan.id}`);

  // const productResp = await firebaseDbGateway.queryCollection("planFeatures", [
  //   ["stripeIds.dev", "==", subscription.items[0].plan.id],
  // ]);

  // const planFeatures = productResp.data[0];

  // TODO: planFeatures: orgResp.data.planFeatures, is meant to keep planFeatures and remove the query to stripeIds.dev
  // which was removed from the plans file. The planFeatures here can likely be removed
  return {
    subscription: subscriptionData,
    planFeatures: orgResp.data.planFeatures,
  };
}
/**
 *
 * @param {*} userId
 * @returns
 */
async function getUserDocument(userId) {
  if (!userId) {
    throw new Error("Missing user Id. userId required");
  }
  const docResp = await firebaseDbGateway.getDocument("users", userId);
  return docResp.data;
}
/**
 *
 * @param {*} param0
 * @returns {*} {viewModel}
 */
function subscriptionPresenter({ subscription, planFeatures }) {
  if (!subscription || !planFeatures) {
    return { error: "Could not load subscription data" };
  }

  const card = subscription?.custom__default_payment_method?.card;

  console.log(`subscription before returning presenter is name is `);
  console.log( subscription.items[0].price.product.name );

  // Presenter object.
  return {
    subscription: {
      status: subscription?.status,
      default_payment_method: subscription.default_payment_method,
      nickname: subscription?.items[0]?.price.product.name,
      amount:
        "$" + (subscription?.items[0]?.plan?.amount_decimal / 100).toFixed(2),
      // converting stripe UNIX timestamp to date
      current_period_end: moment(
        new Date(subscription.current_period_end.seconds * 1000)
      ).format("MMM DD yyyy"),
      card_name: card ? `${card?.brand} ....${card?.last4}` : "None",
      
    },
    // adam: maybe shoud add current limits to stats also?
    planFeatures: [...planFeatures.features],
  };
}
/**
 *
 * @param {*} stripeId
 * @param {*} token
 * @returns
 */
async function getInvoices(stripeId, token) {
  if (!stripeId || !token) {
    throw new Error("customer stripeId and firebase auth token required");
  }

  // debugger;

  const invoiceResp = await httpGateway.get(
    `${process.env.NEXT_PUBLIC_APP_HOST}/api/stripe/get-invoices?id=${stripeId}`,
    token
  );

  return invoiceResp.data;
}
function invoicePresenter(invoices) {
  if (!invoices) {
    return {
      error: "Could not load invoices.",
    };
  }
  let invoiceViewModel = [];

  for (let index in invoices) {
    const invoice = invoices[index];

    //TODO: Check if query can filter response from stripe
    if(invoice.status === "paid") {
      let invoiceResult = {
        date: moment(new Date(invoice.created * 1000)).format("MMM DD yyyy"),
        amount: "$" + invoice.amount_due / 100,
        description: invoice.lines.data[0]?.description,
        href: invoice.invoice_pdf,
      };

      invoiceViewModel.push(invoiceResult);
    }
  }
  return invoiceViewModel;
}

/**
 *
 * @param {*} integration
 * @param {*} token
 * @returns
 */
async function deleteAsanaIntegration(integration, token) {
  const requestData = {
    id: integration.id,
    // type = 'asana'
    type: integration.type,
  };

  await httpGateway
    .post(
      `${process.env.NEXT_PUBLIC_APP_HOST}/api/integrations/disconnect`,
      requestData,
      token
    )
    .catch((err) => {
      console.error(err);
    });

  const deletionResult = await firebaseDbGateway.deleteDocumentFromCollection(
    "integrations",
    integration.id
  );

  return deletionResult;
}

/**
 *
 * @param {*} integration
 * @param {*} token
 * @returns
 */
const deleteIntegration = deleteAsanaIntegration

export {
  getSubscription,
  getUserDocument,
  subscriptionPresenter,
  getInvoices,
  invoicePresenter,
  deleteAsanaIntegration,
  deleteIntegration
};