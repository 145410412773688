import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useUser } from 'next-firebase-auth';
import { useQuery } from 'react-query';
import ClockIcon from "@heroicons/react/24/outline/ClockIcon";
import { getUserDocument } from '../../services/settings';
import { updateUser } from '../../services/user';

const RecentlyVisitedPages = () => {
  const router = useRouter();
  const AuthUser = useUser();
  const [recentPages, setRecentPages] = useState([]);

  const { data: userData } = useQuery(
    ["user", AuthUser.id],
    async () => {
      return getUserDocument(AuthUser?.id);
    },
    { enabled: !!AuthUser.id }
  );

  useEffect(() => {
    if (userData) {
      if (userData?.recentUrls) {
        localStorage.setItem('visitedPages', JSON.stringify(userData?.recentUrls))
      } else {
        localStorage.setItem('visitedPages', JSON.stringify([]))
      }
    }
  }, [userData])

  useEffect(() => {
    // Retrieve recently visited pages from localStorage or another data source
    const storedRecentPages = JSON.parse(localStorage.getItem('visitedPages')) || [];
    setRecentPages(storedRecentPages);
    if (userData) {
      userData.recentUrls = storedRecentPages;
      const updateUserDoc = async () => {
        await updateUser(AuthUser.id, userData);
      }
      updateUserDoc();
    }
  }, [localStorage.getItem('visitedPages'), userData]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const updateRecentPages = (page) => {
    const updatedPages = [page, ...recentPages.filter((p) => p !== page)].slice(0, 15);
    setRecentPages(updatedPages);
    localStorage.setItem('visitedPages', JSON.stringify(updatedPages));
  };

  const dropdownOptions = recentPages?.map((page) => ({
    label: page,
    value: page,
  }));

  const handleClick = (row) => {
    const selected = row?.value;
    if (selected) {
      router.push(selected);
      updateRecentPages(selected);
    }
  }

  return (
    <>
      <Menu as="div" className="relative ml-3">
        <div>
          <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none lg:rounded-md lg:p-2 lg:hover:bg-gray-50">
            <div className="relative">
              <span className="sr-only">View History</span>
              <ClockIcon className="h-6 w-6" aria-hidden="true" />
              <span
                className={`absolute top-0 right-0 block h-2 w-2 -translate-y-1/2 translate-x-1/2 transform rounded-full ring-2 ring-white`}
              />
            </div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute p-4 z-10 mt-3 min-w-192 right-0 text-gray-500 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" style={{ "minHeight": dropdownOptions?.length > 0 ? 250 : 150 }}>
            <div className="flex mb-3 items-center">
              <div className="ml-3  w-full text-xl font-medium text-black">
                Recently visited pages
              </div>
            </div>
            <hr className="mb-2" />
            {dropdownOptions?.length > 0 ?
              <>
                {dropdownOptions?.map((page, i) => (
                  <Menu.Item key={i} as={Fragment} onClick={() => handleClick(page)}>
                    {({ active }) => (
                      <a
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "flex px-4 py-2 text-sm cursor-pointer w-full text-left"
                        )}
                      >
                        {page?.label}
                      </a>
                    )}
                  </Menu.Item>
                ))}
              </> : <div
                className="inline-flex w-full m-6 pr-6 justify-center items-center text-lg text-gray-500"
              >
                No Recent History
              </div>
            }
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default RecentlyVisitedPages;