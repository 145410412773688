//import 'primeflex/primeflex.css';
//import '../../index.css';
// import ReactDOM from 'react-dom';

import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
// import { Button } from 'primereact/button';
import { Disclosure } from "@headlessui/react";
//import './NewDashboardDialog.css';
import { InputText } from "primereact/inputtext";
import firebase from "firebase/compat/app"
import "firebase/compat/firestore"
import { useRouter } from "next/router";

import { PlusSmallIcon } from "@heroicons/react/24/outline";
export default function NewDashboardDialog(props) {

  const {displayBasic, setDisplayBasic } = props;
  const [value1, setValue1] = useState("");
  const db = firebase.firestore();
  const router = useRouter();
  const { oid } = router.query;

  // const onClick = (name, position) => {
  //   setDisplayBasic(true);
  // };

  const onHide = () => {
    setDisplayBasic(false);
    setValue1("")
  };

  const save = async () => {
    console.log(`saving dash ${value1}`);
    const dashDocRef = await db.collection("dashboards").add({
      name: value1,
      dateCreated: new Date(),
      orgId: oid,
    });
    setDisplayBasic(false);
    setValue1("")

    console.log(`Created dash doc ${dashDocRef.id} for oid ${oid}`);
    router.push(`/org/${oid}/dashboards/${dashDocRef.id}`, undefined, {
      shallow: true,
    });
  };

  const renderFooter = (name) => {
    return (
      <div>
        <button
          label="No"
          icon="pi pi-times"
          onClick={() => onHide(name)}
          className="p-button-text"
        >
          Cancel
        </button>
        <button
          label="Yes"
          icon="pi pi-check"
          className="ml-3 rounded-md border border-transparent bg-indigo-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-600 focus:outline-none cursor-pointer"
          onClick={save}
          autoFocus
        >
          Save
        </button>
      </div>
    );
  };

  return (
    <>
      {/* <button
        className='className="group flex w-full items-center rounded-md py-2 pl-11 pr-2 text-sm font-medium text-gray-100 hover:text-white hover:bg-gray-600"'
        label="Show"
        icon="pi pi-external-link"
        onClick={() => onClick("displayBasic")}
      >
        <PlusSmallIcon
          className="mr-2 h-4 w-4 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
          aria-hidden="true"
        />
        Add Dashboard
      </button> */}
      <Dialog
        header="Create a dashboard"
        visible={displayBasic}
        style={{ width: "50vw" }}
        footer={renderFooter("displayBasic")}
        onHide={() => onHide("displayBasic")}
      >
        <div>
          <h6>Dashboard name</h6>
          <InputText
            className="w-full"
            value={value1}
            onChange={(e) => setValue1(e.target.value)}
            placeholder="My dashboard..."
          />
        </div>
        <div className="ml-2 mt-8">
          {value1}
        </div>
      </Dialog>
    </>
  );
}

// const rootElement = document.getElementById("root");
// ReactDOM.render(<NewDashboardDialog />, rootElement);
